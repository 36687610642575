import React from "react";
import AnalyticsEvent from "../../shared/AnalyticsEvent";

const blog_202311 = [
  {
    "img": "/images/site/blog/20231111_our-thoughts-on-the-upcoming-changes-to-a-level-curriculum.png",
    "tag": "News",
    "title": "Our thoughts on the upcoming changes to A level curriculum",
    "slug": "our-thoughts-on-the-upcoming-changes-to-a-level-curriculum",
    "date": "11 November 2023",
    "publishDate": "2023-11-11T12:00:00.000+08:00",
    "content":
    <React.Fragment>
      <div className="post-meta pb-100"><a href="/blog" className="theme-btn-five"><i className="fa fa-arrow-left"></i> Back</a>
        <div className="tag">11 Nov 2023</div>
        <h3 className="title">
          Our thoughts on the upcoming changes to A level curriculum
        </h3>
        <p>We have earlier highlighted in <a href="/blog/changes-to-a-level-curriculum" target="_blank">our blog post in March</a> on the upcoming changes announced by MOE to the A-Level curriculum. Here's a quick summary to recap.</p>
        <p>The Ministry of Education (MOE) announced significant changes to the GCE A-Level examination scoring system which will take effect from 2026 onwards. This move aims to reduce the over-emphasis on academics and grades, while providing students with more flexibility to explore interests beyond scoring well.</p>
        <p>The key changes are:</p>
        <h4 className="title">Removal of Mid-Year Exams</h4>
        <ul>
          <li>Starting 2024, mid-year exams will be progressively removed for all Junior College (JC) students. This eliminates a major high-stakes examination that previously contributed significantly to a student's overall grade.</li>
          <li>The time savings from not preparing for mid-years can be used for more meaningful learning through diverse experiences. Students can immerse in passion projects, community service and holistic development.</li>
          <li>However, schools cannot replace mid-years with more frequent school-based weighted assessments. There will be a cap of only one weighted assessment per subject per term.</li>
        </ul>

        <h4 className="title">Revised Scoring for Project Work</h4>
        <ul>
          <li>From 2024 onwards, Project Work will be graded as pass/fail instead of scoring. Students must pass Project Work to qualify for university admission.</li>
          <li>This reduces the academic pressure on Project Work, which is meant to develop creativity, teamwork and other skills. Students can explore their interests without chasing grades.</li>
          <li>The Project Work curriculum remains rigorous as students still need to demonstrate relevant skills and competencies. But the stakes are lowered by removing grades.</li>
        </ul>

        <h4 className="title">Fourth Subject only counts if it improves score</h4>
        <ul>
          <li>Currently, the best 3 H2 and 1 H1 subject scores are counted towards the University Admission Score, which is out of 90.</li>
          <li>From 2026, the fourth content-based subject will only count towards the overall score if it pulls up the student's score.</li>
          <li>This gives students the flexibility to take an additional subject they are interested in but may not excel at. Previously, students avoided such subjects as it may pull down their score.</li>
          <li>With this change, students can explore more subjects and the maximum score will be reduced to 70 points.</li>
          <li>Students can take 3 H2 and 1 H1 subject or 4 H2 subjects. Their weakest H2 subject will not automatically count as an H1.</li>
        </ul>

        <h4 className="title">General Paper Made Compulsory</h4>
        <ul>
          <li>From 2024, all JC students must take General Paper rather than the Knowledge & Inquiry subject. General Paper will develop core competencies in language, writing and current affairs.</li>
          <li>Knowledge & Inquiry will still be offered for students who prefer learning about the nature and construction of knowledge.</li>
          <li>Making General Paper compulsory ensures all JC students build critical language and thinking skills needed for university.</li>
        </ul>

        <h4 className="title">More Mixing Across Academic Tiers with Full Subject-Based Banding</h4>
        <ul>
          <li>Full subject-based banding will be implemented in 120 secondary schools from 2024. Students will be grouped into 3 tiers based on PSLE scores only for posting purposes.</li>
          <li>Subjects will be offered across different tiers. Students will take subjects based on their strengths, not their posted tier. There will be greater diversity within form classes.</li>
          <li>This creates flexibility for students to customize subject combinations based on interests and abilities. Their posted tier does not limit subject choices.</li>
          <li>Specialised schools are exempted as they already offer niche programmes.</li>
        </ul>

        <img
          src="/images/site/blog/20230303_changes-curriculum.png"
          alt="Changes to curriculum"
          style={{ maxHeight: '550px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '20px', paddingBottom: '20px' }}
        />

        <p>These changes are part of MOE's continuous efforts to create a more holistic education system. By reducing the focus on grades and creating space for passions to grow, students can enjoy learning for its own sake. As a trusted tuition provider, EduZ Tuition recommends the following:</p>

        <h4 className="title">Recommendations for Parents:</h4>
        <ul>
          <li>Understand your child's unique interests and strengths. Guide them towards subjects they are intrinsically motivated to learn.</li>
          <li>Focus on their learning journey and progress rather than grades. Help them set meaningful goals.</li>
          <li>Allow time for non-academic pursuits. Support their holistic development through CCAs and other learning opportunities.</li>
          <li>Work closely with teachers and tutors to customize your child's learning experience based on their needs and aspirations.</li>
        </ul>

        <h4 className="title">How EduZ Tuition Provides Support:</h4>
        <p>As a leading tuition centre, EduZ Tuition has experience helping students through major education policy changes. Here is how we can support your child during this transition:</p>
        <ul>
          <li>Our tutors include ex-MOE teachers, graduate tutors and experienced educators. They understand the objectives behind these changes and can provide guidance to students.</li>
          <li>We offer tuition across academic tiers for secondary school and JC subjects. Our small class sizes allow personalized attention to each student's strengths and interests.</li>
          <li>Beyond covering the academic curriculum, we focus on igniting curiosity and a love for learning. Students develop critical thinking and problem solving skills.</li>
          <li>Our tutors and consultants are available to advise on subject combinations, balancing academic load, and pathways to maximize opportunities.</li>
          <li>We partner with parents and teachers to align goals and customize learning for each child. Our priority is nurturing inquisitive young minds.</li>
        </ul>

        <p>As MOE continues enhancing the education system to meet Singapore's future needs, EduZ Tuition stands ready to help every student thrive in this new environment. We aim to be a trusted partner for families seeking quality tuition and guidance during this important transition.</p>

        <p></p>
        <div style={{ textAlign: 'center' }}>
          <p>Keen to learn more about Eduz Tuition?</p>
          <a href="https://wa.me/+6590625285?lang=en"
          target="_blank"
          rel="noreferrer"
          className="theme-btn-five"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
          onClick={() => AnalyticsEvent({
            category: "WhatsAppEnquire",
            action: "Click",
            source: "/blog/our-thoughts-on-the-upcoming-changes-to-a-level-curriculum",
            label: "blogClickWhatsAppEnquire"
          })}
        >
        <i className="fa fa-whatsapp"></i> Chat with us now
      </a>
      </div>
      <p></p>
      </div>
    </React.Fragment>
  },
]


export default blog_202311